import React from "react";
import { Helmet } from "react-helmet";

const WillemPeuscher = () => {
  return (
    <>
      <Helmet>
        <meta charset='UTF-8' />
        <meta http-equiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Email Signature</title>
      </Helmet>
      <div>
        <table
          style={{
            width: "405px",
            borderCollapse: "collapse",
            borderSpacing: "0px",
            fontFamily: "Myriad Pro, Arial, Helvetica, sans-serif",
            fontWeight: "normal",
            verticalAlign: "top",
            lineHeight: "100%",
          }}
          cellpadding='0'
          cellspacing='0'
          valign='top'
          border='0'
        >
          <tr>
            <td
              style={{
                lineHeight: "100%",
                padding: "0",
                margin: 0,
                color: "#555555",
              }}
            >
              <p style={{ lineHeight: "100%", padding: 0, margin: 0 }}>
                <span
                  style={{
                    fontSize: "14pt",
                    fontWeight: "bold",
                    color: "#0055b8",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Willem Peuscher
                </span>
                <br />
              </p>
              <p style={{ lineHeight: "100%", padding: 0, margin: 0 }}>
                <span style={{ fontSize: "11pt", color: "#d9a728" }}>
                  Partner
                  <br />
                  <span style={{ fontSize: "10pt", color: "#0055b8" }}>
                    <b>VIRSAT L.L.C.</b> | Virtual Reality Safety Training
                  </span>
                </span>
              </p>

              <hr
                style={{
                  margin: "10px 0",
                  height: "1px",
                  border: "none",
                  background: "#cccccc",
                }}
              />
              <span style={{ fontSize: "9.5pt" }}>Mobile: +31 1 12345678</span>
              <br />
              <span style={{ fontSize: "9.5pt" }}>Tel.: +31639788187</span>
              <br />
              <span style={{ fontSize: "9.5pt" }}>
                NL: Plantagekade 10, Postcode 1018 ZV, Amsterdam
              </span>
              <br />
              <span style={{ fontSize: "9.5pt" }}>
                UAE: Tamouh Tower 22-C1, Al Reem Island, Marina Square, Abu
                Dhabi
              </span>
              <br />
              <a
                style={{
                  color: "#3fc6f7",
                  fontSize: "9.5pt",
                  textDecorationColor: "#3fc6f7",
                }}
                href='mailto:willemp@virsat.com'
              >
                willemp@virsat.com
              </a>
              <br />
              <a
                style={{
                  color: "#3fc6f7",
                  fontSize: "9.5pt",
                  textDecorationColor: "#3fc6f7",
                }}
                href='https://www.virsat.com/'
              >
                www.virsat.com
              </a>
              <br />
              <br />
              <img src="https://secure.virsat.com/img/email/willem.png" alt='Virsat Logo and QR Code' />
              <hr
                style={{
                  margin: "10px 0",
                  height: "1px",
                  border: "none",
                  background: "#cccccc",
                }}
              />
              <p
                style={{
                  fontSize: "7pt",
                  color: "#999999",
                  textAlign: "justify",
                  lineHeight: "1",
                  padding: "0%",
                  margin: "0%",
                }}
              >
                Disclaimer: This e-mail contains information intended only for
                the use of the individual or entity named above. If the reader
                of this e-mail is not the intended recipient or the employee or
                agent responsible for delivering it to the intended recipient,
                any dissemination, publication or copying of this e-mail is
                strictly prohibited. The sender does not accept any
                responsibility for any loss, disruption or damage to your data
                or computer system that may occur while using data contained in,
                or transmitted with, this e-mail. If you have received this
                e-mail in error, please immediately notify us by return e-mail
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "0px",
                fontSize: "13px",
                color: "#555555",
                lineHeight: "100%",
                margin: 0,
              }}
            ></td>
          </tr>
        </table>
        <p
        style={{
            margin: "10px 0",
            lineHeight: "1",
            padding: "0%",
            }}>
            <img src="https://secure.virsat.com/img/email/Adipec-2022.jpg" alt="Adipec 2022" width="405"/>
        </p>
      </div>
    </>
  );
};

export default WillemPeuscher;
